<template>
  <b-sidebar
    id="sidebar-create-edit"
    ref="sidebar-create-edit"
    bg-variant="white"
    right
    backdrop
    shadow
    :title="this.dataModules.id == null ? 'Cadastrar UAD' : 'Editar UAD'"
  >
  <div>
    <!-- Name -->
    <b-form-group
      label="* Nome:"
      label-for="i-nome"
      class="pl-1 pr-1"
    >
      <b-form-input
        id="i-nome"
        type="text"
        placeholder="Ex: TI"
        v-model="dataModules.nome"
      />
    </b-form-group>
    <b-form-group
      label="* Objetivo Min. (%):"
      label-for="i-nome"
      class="pl-1 pr-1"
    >
      <b-form-input
        id="i-nome"
        type="number"
        placeholder="Ex: 34"
        v-model="dataModules.requisito_min"
      />
    </b-form-group>
    <b-form-group
      label="* Meta:"
      label-for="i-meta"
      class="pl-1 pr-1"
    >
      <b-form-input
        id="i-meta"
        type="text"
        v-model="dataModules.meta"
      />
    </b-form-group>

    <b-row class="ml-1 mr-1 pb-1">
      <b-button variant="relief-primary" block @click="storeAndUpdate">
        {{this.dataModules.id == null ? 'Salvar' : 'Editar'}}
      </b-button>
    </b-row>
    <b-row class="ml-1 mr-1 pb-1">
      <b-button variant="relief-danger" block @click="initValues">
        Limpar
      </b-button>
    </b-row>
  </div>
    
  </b-sidebar>
</template>
<script>
/* eslint-disable */
import { VBToggle } from 'bootstrap-vue'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
   props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    mapActions,
    mapState
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data(){
    return {
      // Variáveis Global

      dataModules: {
        requisito_min: null,
        nome: null,
        id: null,
        meta: null,
        pathAPI: 'uad'
      },
    }
  },
  computed: {
    ...mapState("connectAPI", ["modules"])
  },
  methods: {
    ...mapState("connectAPI", ["store", "update"]),

    initValues(){
      this.dataModules.id = null,
      this.dataModules.nome = null
      this.dataModules.meta = null
      this.dataModules.requisito_min = null
    },

    storeAndUpdate() {
      if (this.dataModules.id == null) { 
        this.$store.dispatch("connectAPI/store", {
          data: this.dataModules
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cadastrado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
          this.initValues()
        })
        .finally(() => {
          this.$emit('fetch-all');
          this.closeSidebar()
        })
      } else {
        this.$store.dispatch("connectAPI/update", {
          data: this.dataModules
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Atualizado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'info'
            }
          })
          this.initValues();
        })
        .finally(() => {
          this.$refs['sidebar-create-edit'].hide()
          this.$emit('fetch-all');
        })
      }
    },

    closeSidebar(){
      this.$refs['sidebar-create-edit'].hide()
    }
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
        if (this.dataProp.id == null) { 
          this.initValues(); 
        } else { 
          this.dataModules.id = this.dataProp.id;
          this.dataModules.nome = this.dataProp.nome;
          this.dataModules.meta = this.dataProp.meta;
          this.dataModules.requisito_min = this.dataProp.requisito_min
        } 
      }
    }
  },
  created() {
  },
}
</script>